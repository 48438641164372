const translation = {
  Settings: 'Inställningar',
  'Dark mode': 'Mörkt läge',
  Language: 'Språk',
  Notifications: 'Notifikationer',
  Dashboard: 'Översikt',
  Competences: 'Kompetenser',
  Competence: 'Kompetens',
  History: 'Historik',
  Licenses: 'Körtillstånd',
  License: 'Körtillstånd',
  Account: 'Konto',
  Logout: 'Logga ut',
  'Last active ': 'Senast aktiv ',
  'Has not logged in': 'Har inte loggat in',
  'Add verification': 'Skapa verifiering',
  Name: 'Namn',
  Phone: 'Telefonnummer',
  Description: 'Beskrivning',
  Cancel: 'Avbryt',
  'Create a new competence': 'Skapa ny kompetens',
  'Your account': 'Ditt konto',
  General: 'Allmänt',
  'Email addresses': 'E-postadresser',
  'More options': 'Mer alternativ',
  'Add task': 'Lägg till uppgift',
  'Have you done the task?': 'Har du gjort uppgiften?',
  'Expiring or expired competences': 'Förfallna eller förfallande kompetenser',
  'If you choose to delete your account all the data that the system has on your account will be deleted permanently.':
    'Om du väljer att radera ditt konto kommer all data som systemet har på ditt konto att raderas permanent.',
  'Phone numbers': 'Mobilnummer',
  'Add competences': 'Lägg till kompetenser',
  'Choose as requirements': 'Välj som krav',
  'Choose requirements': 'Lägg till krav',
  'Add company competences as requirements': 'Välj interna som krav',
  'Nice to know': 'Våra kompetenser',
  'Save changes': 'Spara ändringar',
  'Not validated competences': 'Anställda som saknar attestering',
  'All validated competences': 'Anställda som uppfyller attestering',
  Certificates: 'Bevis',
  Library: 'Bibliotek',
  'Mobile BankID on another device': 'Mobilt BankID på annan enhet',
  'Open BankID': 'Öppna BankID',
  Authorizations: 'Behörigheter',
  'Cancel login': 'Avbryt inloggning',
  'Assign authorizations': 'Tilldela behörigheter',
  'Update authorization': 'Uppdatera behörighet',
  'Create license': 'Skapa körtillstånd',
  'Upload files': 'Ladda upp filer',
  Type: 'Typ',
  Machine: 'Maskin',
  Locations: 'Platser',
  Addresses: 'Adresser',
  Goods: 'Gods/material',
  'Your email is connected': 'Din e-postadress är kopplad',
  'To finish the connection press connect.':
    'För att slutföra kopplingen tryck på koppla.',
  'User has accepted the connection to your company.':
    'Användaren har accepterat kopplingen till ert företag.',
  'User has not yet accepted the connection to your company.':
    'Användaren har inte accepterat kopplingen till ert företag ännu.',
  'User cannot login to the system.': 'Användaren kan ej logga in i systemet.',
  'Kompetensvy tags': 'Kompetensvy taggar ',
  'Internal tags': 'Interna taggar',
  'Verify employee competences': 'Kravkompetenser kräver attestering',
  Validated: 'Attesterade',
  'Validated requirements': 'Attesterade krav',
  'Remove validate': 'Ta bort attestering',
  'User has denied the connection to your company.':
    'Användaren har nekat kopplingen till ert företag.',
  'Conditions of the truck': 'Truckens förhållanden',
  'Machine name': 'Maskinens namn',
  Gear: 'Redskap',
  Trucks: 'Truckar',
  'Machine driven lifting devices': 'Maskindrivna lyftanordningar',
  Lifts: 'Liftar',
  Cranes: 'Kranar',
  Inside: 'Inomhus',
  Outside: 'Utomhus',
  'Public road': 'Allmän väg',
  'A1 - low lift order picker': 'A1 - Låglyftande plocktruck',
  'A2 - Joint stacker': 'A2 - Ledstaplare',
  'A3 - Low lift pickup truck': 'A3 - Låglyftande plocktruck',
  'A4 - Low lifter': 'A4 - Låglyftare',
  'B1 - Counterbalance truck': 'B1 - Motviktstruck',
  'B2 - High-lift stacker': 'B2 - Höglyftande åkstaplare',
  'B3 - Reach truck': 'B3 - Skjutstativtruck',
  'B4 - High-lift pick-up truck': 'B4 - Höglyftande plocktruck',
  'B5 - Narrow aisle truck': 'B5 - Smalgångstruck',
  'B6 - Four-way truck': 'B6 - Fyrvägstruck',
  'C2 - Wheel loader': 'C2 - Hjullastare',
  'C3a - Container truck': 'C3a - Containertruck',
  'C3b - Timber truck': 'C3b - Timmertruck',
  'C4 - Sideloader': 'C4 - Sidlastare',
  'C5 - Border truck': 'C5 - Grensletruck',
  'C7 - Telescopic loaders': 'C7 - Teleskoplastare',
  'D1 - Drag/flatbed truck': 'D1 - Drag/Flaktruck',
  'D2 - Terminal tractor': 'D2 - Terminaldragtruck',
  'A1 - Traverse radio operated': 'A1 - Travers radiomanövrerad',
  'A2 - Traverse hanging maneuvered': 'A2 - Travers hängmanövrerad',
  'A3 - Traverse cab maneuvered': 'A3 - Travers hyttmanövrerad',
  'B1 - Portal radio operated': 'B1 - Portal radiomanövrerad',
  'B2 - Portal hanging maneuvered': 'B2 - Portal hängmanövrerad',
  'B3 - Portal cab maneuvered': 'B3 - Portal hyttmanövrerad',
  'C1 - Portal radio operated': 'C1 - Portal radiomanövrerad',
  'C2 - Portal hanging maneuvered': 'C2 - Portal hängmanövrerad',
  'C3 - Portal cab maneuvered': 'C3 - Portal hyttmanövrerad',
  'D1 - Portal radio operated': 'D1 - Portal radiomanövrerad',
  'D2 - Portal hanging maneuvered': 'D2 - Portal hängmanövrerad',
  'D3 - Portal cab maneuvered': 'D3 - Portal hyttmanövrerad',
  'E1 - Portal radio operated': 'E1 - Portal radiomanövrerad',
  'E2 - Portal hanging maneuvered': 'E2 - Portal hängmanövrerad',
  'E3 - Portal cab maneuvered': 'E3 - Portal hyttmanövrerad',
  'F1 - Portal radio operated': 'F1 - Portal radiomanövrerad',
  'F2 - Portal hanging maneuvered': 'F2 - Portal hängmanövrerad',
  'F3 - Portal cab maneuvered': 'F3 - Portal hyttmanövrerad',
  'A1 - Scissors / column lift with support legs':
    'A1 - Sax/pelarlift med stödben',
  'A2 - Track-bound scissor lift': 'A2 - Spårbunden saxlift',
  'A3 - Self-propelled scissors / column lift':
    'A3 - Självgående sax/pelarlift',
  'B1 - Car / Trailer-mounted boom lift with support legs, caterpillar / crawler lift':
    'B1 - Bil/Släpvagnsmonterad bomlift med stödben, larv/bandburen lift',
  'B2 - Track-bound boom lift / under-bridge lift':
    'B2 - Spårbunden bomlift/under-bro-lift',
  'B3 - Self-propelled boom lift / Folding arm lift':
    'B3 - Självgående bomlift/Vikarmslift',
  'Machine name (optional)': 'Maskinens namn (valfritt)',
  'Workplace (optional)': 'Arbetsplats (valfritt)',
  'Tasks (optional)': 'Arbetsuppgifter (valfritt)',
  'Goods/material (optional)': 'Gods/material (valfritt)',
  'Conditions of the truck (optional)':
    'Truckens förhållanden, exempelvis vikt, lyfthöjd (valfritt)',
  'Gear that will be used (optional)': 'Redskap som kommer användas (valfritt)',
  'Risk assessment comment (optional)': 'Riskbedömningskommentar (valfritt)',
  'What machine will be used with this license.':
    'Vilken maskin ska användas med detta körtillstånd.',
  'Where this license will be used.':
    'Vart detta körtillstånd kommer att användas.',
  'What tasks/work will this license allow for.':
    'Vilka uppgifter/arbete kommer detta körtillstånd tillåta.',
  'What type of goods/material will be handled.':
    'Vilket gods/material kommer hanteras.',
  'Read the truck manual, we recommend you upload manual on the trucks that will be used.':
    'Läs truck manualen, vi rekommenderar att ni bifogar manualen på truckar som ska användas.',
  'Read the gear manual, we recommend you upload manual on the gear that will be used.':
    'Läs redskapens manual, vi rekommenderar att ni bifogar manualen på redskap som ska användas.',
  'We recommend that you upload complete documentation on the risk assessments based on the work that will be done regarding this license.':
    'Vi rekommenderar att ni laddar upp riskbedömningar för arbetet som kommer att ske relaterat till körtillståndet.',
  'Choose locations for where the license will be used.':
    'Välj vilka platser för vart körtillståndet ska användas.',
  'At what addresses will the license be used.':
    'På vilka adresser kommer körtillståndet att användas på.',
  'Important information. If the license requires driving on a public road please make sure you follow legal compliances that apply to public roads.':
    'Viktig information: Om körtillståndet kräver körande på allmän väg, se till att ni följer lagkrav gällande allmänna vägar.',
  'Choose files that users can download when reading about this license.':
    'Välj filer användare kan ladda hem när dom tittar på detta körtillstånd.',
  'We recommend that you fill out all the fields to ensure legal compliance. We also recommend seperate licenses for different types.':
    'Vi rekommenderar att ni fyller i alla fält för att säkra lagefterlevnad. Vi rekommenderar även separata körtillstånd för olika typer av maskiner.',
  'Assign new authorizations': 'Tilldela nya behörigheter',
  'Authorizations information': 'behörighets information',
  Report: 'Rapporter',
  'Filter employees': 'Filtrera anställda',
  'Latest run': 'Senast skickat',
  'Days between reminders': 'Dagar mellan påminnelser',
  'Monthly reminders': 'Månadspåminnelse',
  'Send monthly reminders about expiring competences to admins, executives and authorizations.':
    'Skicka påminnelser varje månad om förfallande kompetenser till admins, chefer och behörigheter.',
  'Send reminders to admins about the state of the entire company':
    'Skicka påminnelse till administratörer på statusen hos företaget',
  'Send reminders to executives about the state of selected organisations':
    'Skicka påminnelse till chefer om valda organisationer',
  'Send reminders to authorized about the state of selected organisations':
    'Skicka påminnelse till personer med högre behörighet om valda organisationer',
  'Send reminders to employee about missing, expiring or expired competences':
    'Skicka påminnelse till anställda om saknade, förfallande eller förfallna kravkompetenser',
  'Connected employees': 'Kopplade anställda',
  'Create groups of authorizations to easier find them in the list.':
    'Skapa grupper av behörigheter för att enklare hitta dom i listan, eller något namn som passar för er.',
  'Choose which employees will get the higher authorizations, this will give them the option to administrate people under the chosen organisation tags. The users must have an account and be connected to your company.':
    'Välj vilka anställda som ska få högre behörighet. Detta ger dom möjligheten att administrera personer under valda organisationstaggar. För att bli tilldelad behörighet måste personen ha ett konto och vara kopplad mot ert företag.',
  Resellers: 'Återförsäljare',
  Companies: 'Företag',
  Employees: 'Anställda',
  'First name': 'Förnamn',
  'Last name': 'Efternamn',
  Address: 'Adress',
  'Zip code': 'Postnummer',
  City: 'Stad',
  Relative: 'Anhörig',
  'Expiring date for': 'Förfallodatum för',
  'Create certificate': 'Spara',
  'Only connected employees or employees that are revoked or have denied the permission are shown. Permissions needs to be accepted by the employee to be valid therefore only connected employees can be selected. If the employee has denied the permission you can send it again.':
    'Endast kopplade anställda visas i denna lista. Tillstånd måste accepteras av den anställde för att vara godkänd. Om den anställde har nekat tillståndet kan du utfärda det igen.',
  'You can choose multiple competences. If the competences you choose have a specific expiration time the system will automatically calculate how long the competences are valid.':
    'Du kan välja flera kompetenser. Om kompetenserna du väljer har förfallodatum kommer systemet automatiskt beräkna hur länge dina kompetenser är giltiga.',
  'Select competences': 'Välj kompetenser',
  'Select all': 'Markera alla',
  'Relatives phone number': 'Anhörigs mobilnummer',
  'Contact person': 'Kontaktperson',
  'Contact person phone': 'kontaktperson mobilnummer',
  'Your competences': 'Dina kompetenser',
  'Close menu': 'Stäng meny',
  'Open menu': 'Öppna meny',
  'Add license folder': 'Lägg till mapp',
  Delete: 'Ta bort',
  'Rename license folder': 'Byt namn på mapp',
  'Delete license folder': 'Ta bort mapp',
  'Delete selected license folder?': 'Ta bort vald körtillstånds mapp?',
  'Sort by': 'Sortera via',
  'Name (asc)': 'Namn (stigande)',
  'Name (desc)': 'Namn (fallande)',
  'Status (asc)': 'Status (stigande)',
  'Status (desc)': 'Status (fallande)',
  'Description (asc)': 'Beskrivning (stigande)',
  'Description (desc)': 'Beskrivning (fallande)',
  'Date (desc)': 'Datum (fallande)',
  'Date (asc)': 'Datum (stigande)',
  'Type (asc)': 'Typ (stigande)',
  'Type (desc)': 'Typ (fallande)',
  'Rows per page': 'Rader per sida',
  'Task description': 'Uppgiftens beskrivning',
  'Answer type': 'Svarstyp',
  'Certificate is valid if task answer is valid':
    'Kompetens är giltig om uppgiften är giltig',
  'Task expires': 'Uppgift förfaller',
  'Task name': 'Uppgiftens namn',
  'Amount of months': 'Antal månader',
  Day: 'Dag',
  Month: 'Månad',
  January: 'Januari',
  February: 'Februari',
  March: 'Mars',
  April: 'April',
  May: 'Maj',
  June: 'Juni',
  July: 'Juli',
  August: 'Augusti',
  September: 'September',
  October: 'Oktober',
  November: 'November',
  December: 'December',
  'Months to spare': 'Månader till godo',
  Example: 'Exempel',
  'Remove account': 'Ta bort konto',
  'All requirements are handled': 'Alla krav är hanterade',
  'Every year the 31st of May this competence will have to be validated again. We want 3 months to spare back in time. Peter got this competence 2020-04-01, because 1 of April is within 3 months of 31 of May Peters competence will be valid until 31 of May 2021. Instead of 31st of May 2020.':
    'Varje år 31 Maj ska denna kompetens förnyas. Vi vill ha 3 månader bakåt i tiden till godo. Peter har tagit denna kompetens 2020-04-01, eftersom 1 April är inom 3 månader av 31 Maj kommer Peters kompetens vara godkänd till 2021-05-31. Istället för 2020-05-31.',
  'Accepted if within number of months before':
    'Godkänd om utfärdandedatum inom månader före',
  'Valid if other verification step is valid':
    'Godkänd om annan verifiering är godkänd',
  'Pick in the list which requirement you would like to add to to verify the competence.':
    'Välj i listan vilken typ av krav du vill använda för att kompetensen ska anses som giltig.',
  of: 'av',
  Search: 'Sök',
  'Date of issue': 'Utfärdandedatum',
  'Search competences': 'Sök bland kompetenser',
  'Add certificate': 'Lägg till bevis',
  'Choose employees': 'Välj anställda',
  'Send license': 'Skicka tillstånd',
  'Edit profiles': 'Redigera profiler',
  'Add competences and certificates': 'Lägga till kompetenser och bevis',
  'Handle requirements': 'Hantera krav',
  'Connect employees': 'Koppla anställda',
  'Revoke license': 'Återkalla tillstånd',
  'Watched competences': 'Våra kompetenser',
  Valid: 'Giltig',
  NotValid: 'Ej giltig',
  'Not valid': 'Ej giltig',
  Expiring: 'Förfaller',
  'Expiring soon': 'Förfaller snart',
  Expired: 'Har förfallit',
  'Amount of requirements the employee fulfills':
    'Antal krav den anställda uppfyller',
  'Amount of requirements that needs to be handled':
    'Antal krav som bör hanteras',
  'Amount of answers by the employee': 'Antal svar av den anställde',
  'Meets the requirements': 'Uppfyller krav',
  'Has no requirements': 'Har inga krav',
  Fulfills: 'Uppfyller',
  'Invalid phone number': 'Ogiltigt mobilnummer',
  'Renew within': 'Förnyas inom',
  month: 'månad',
  months: 'månader',
  'A task must be done': 'Innehåller uppgift',
  'Not fulfilled': 'Uppfyller inte',
  'Company competences': 'Interna kompetenser',
  'Company competence': 'Företagsanpassad',
  'Create a new internal competence': 'Skapa ny intern kompetens',
  Requirements: 'Krav',
  Handled: 'Hanterad',
  Handle: 'Hantera',
  Expires: 'Förfaller',
  'Handled by': 'Hanterat av',
  'Turn off status for this competence for this person.':
    'Släck varningen på denna kompetens för denna användare',
  'Not handled': 'Ej hanterad',
  'Handled expiring': 'Hantering förfaller',
  'Handled expired': 'Hantering förfallen',
  'All handled': 'Alla hanterade',
  'Not connected': 'Ej kopplad',
  'The user has accepted the connection to your company.':
    'Användaren har accepterat kopplingen till ert företag.',
  'Connect employee': 'Koppla anställd',
  Profile: 'Profil',
  'Remove employee': 'Radera anställd',
  'Add employment': 'Skapa anställd',
  'Employee information': 'Anställds information',
  'Employment information': 'Anställningsinformation',
  'Social security number': 'Personnummer',
  'Employment number': 'Anställningsnummer',
  'mobile number': 'Mobilnummer',
  'Mobile number': 'Mobilnummer',
  'Import employees': 'Importera anställda',
  'Template file': 'Importmall',
  'First name and last name is required. You also need to input atleast one email or phone number or social security number per person.':
    'Förnamn och efternamn på den anställda krävs. Man behöver också mata in minst en av e-post, mobil, eller personnummer.',
  email: 'E-postadress',
  Email: 'E-postadress',
  Connected: 'Kopplad',
  'Remove certificate': 'Ta bort bevis',
  'By pressing remove you acknowledge that this cannot be undone and you will remove the certificate.':
    'Genom att trycka ta bort accepterar du att du inte kan ångra detta och beviset kommer att raderas. Raderar du alla bevis kommer hela Kompetensen att raderas.',
  'By pressing remove you acknowledge that this cannot be undone and you will remove the account.':
    'Genom att trycka ta bort accepterar du att du inte kan ångra detta och kontot kommer att raderas.',
  'Missing Handled': 'Ej hanterad',
  'Missing certificate': 'Saknar bevis',
  'Employee connected': 'Anställd redan kopplad',
  'Create employment': 'Skapa anställd',
  'Upload images': 'Ladda upp filer',
  'Choose type of verification': 'Välj typ av verifiering',
  'First name is required': 'Förnamn krävs',
  'Create a new employee': 'Skapa ny anställd',
  'Personal information': 'Personlig information',
  'Add or remove organisations from employees':
    'Lägg till eller ta bort organisation från anställda',
  'Action to take with selected organisations when saving':
    'Välj om du ska lägga till eller ta bort organisation på anställda.',
  'Add organisations to employees': 'Lägg till organisation på anställda',
  'Remove organisations from employees': 'Ta bort organisation på anställda',
  Action: 'Handling',
  'Edit organisations': 'Redigera organisation',
  'No certificate or task added.':
    'Du måste lägga till information före du sparar.',
  'Last name is required': 'Efternamn krävs',
  'Mobile BankID': 'Mobilt BankID',
  'BankID on this unit': 'BankID på denna enhet',
  'Verify social security number': 'Verifiera personnummer',
  Required: 'Krävs',
  'Connected email addresses': 'Kopplade e-postadresser',
  'Add email address': 'Lägg till e-postadress',
  'Email address': 'E-postadress',
  Add: 'Lägg till',
  'To email': 'Skickat till',
  'Waiting for answer': 'Väntar på svar',
  Sent: 'Skickad',
  Date: 'Datum',
  'Abort connecting employee': 'Avbryt',
  'Phone number': 'Mobilnummer',
  'Add phone number': 'Lägg till mobilnummer',
  'To number': 'Till nummer',
  'Connected phone numbers': 'Kopplade mobilnummer',
  'Change company': 'Byt företag',
  Confirm: 'Bekräfta',
  Welcome: 'Välkommen',
  Current: 'Nuvarande',
  'Old certificates': 'Gamla bevis',
  'Expiring date': 'Förfallodatum',
  'Create new': 'Skapa ny',
  'Create new company competence': 'Skapa ny intern kompetens',
  'Kompetensvy competences': 'Kompetensvys kompetenser',
  'Internal competences': 'Interna kompetenser',
  'Create certificates': 'Lägg till kompetenser',
  'Select file': 'Välj fil',
  'Select files': 'Välj filer',
  file: 'fil',
  files: 'filer',
  Drop: 'Släpp',
  'here or click': 'här eller klicka',
  browse: 'bläddra',
  Back: 'Bakåt',
  'Add competence': 'Lägg till kompetenser',
  'Has expired': 'Har förfallit',
  'Create new account': 'Skapa konto',
  'Email or phone number': 'E-post eller mobilnummer',
  Message: 'Meddelande',
  'Send message to employees email or phone':
    'Skicka meddelande till anställdas e-post eller telefon',
  Messages: 'Meddelanden',
  'Course instances': 'Utbildningsmodul (Experimentell)',
  'Email or phone': 'E-post eller mobilnummer',
  'Email or phone is required': 'E-post eller mobilnummer krävs',
  'Forgot password?': 'Återställ lösenord',
  'Password is required': 'Lösenord krävs',
  Password: 'Lösenord',
  'Password again': 'Lösenord igen',
  'Could not login': 'Kunde logga in',
  'Sign in now': 'Logga in',
  'Add employees': 'Lägg till anställda',
  'Missing employments': 'Saknar anställda',
  Edit: 'Redigera',
  'Edit tags': 'Redigera taggar',
  'Competence tags': 'Kompetens taggar',
  Missing: 'Saknar',
  Notes: 'Information',
  Certificate: 'Bevis',
  Copy: 'Kopiera',
  'Remove handled': 'Ta bort hantering',
  'By pressing remove you acknowledge that this cannot be undone and you will remove handled.':
    'Genom att trycka ta bort accepterar du att du inte kan ångra detta och hanteringen kommer att raderas.',
  'Copy permission': 'Kopiera tillstånd',
  'Revoke permission': 'Återkalla tillstånd',
  'If you choose to revoke the permission, the permission is no longer valid.':
    'Om ni väljer att återkalla tillståndet kommer statusen ändras till Återkallat. Alla berörda personer kommer fortfarande ha kvar tillståndet men det är ej aktivt.',
  'You can use the current permission to create a new one. If the new permission is ment to replace this permission make sure to revoke this permission.':
    'Du kan kopiera informationen från det nuvarande tillståndet till ett nytt. Om det nya tillståndet du utfärdar är menat att ersätta det nuvarande se till att återkalla det gamla.',
  Permission: 'Tillstånd',
  'Custom permission': 'Anpassat tillstånd',
  'Valid permission': 'Giltigt tillstånd',
  'Missing permission': 'Saknar tillstånd',
  'Pending permission': 'Väntar på svar',
  'Assign permission': 'Tilldela tillstånd',
  'Assign permission to the selected employees': 'Tilldela tillstånd till de anställda som är valda',
  'Assign work/driving permissions': 'Tilldela arbets-/körtillstånd',
  'Assign work/driving permissions to the selected employees': 'Tilldela arbets-/körtillstånd till de anställda som är valda',
  'Assign permission to employees': 'Tilldela arbets-/körtillstånd till anställda',
  'Selected employees': 'Valda anställda',
  'Select permission': 'Välj tillstånd',
  'If the list is empty, there are no valid permissions for this competence.': 'Om listan är tom så finns det inga giltiga tillstånd för denna kompetens.',
  'Employees with competence': 'Anställda med denna kompetens',
  Save: 'Spara',
  Skip: 'Skippa',
  'Your requirements': 'Dina krav',
  Preview: 'Förhandsgranskning',
  'Here you see your active employments. Click the boxes below to see what requirements you have.':
    'Här ser du din aktiva anställning. Klicka på rutorna nedan för att se vilka kompetenser som gäller för din anställning.',
  'This is a preview of your competence certificates, make sure everything is correct and press save competence.':
    'Detta är en förhandsgranskning av dina bevis, se till att allt är korrekt och klicka på spara.',
  'Select employees you want to print or handle.':
    'Välj personer du vill hantera eller skriva ut rapport för.',
  'Select employees you want to print.':
    'Välj personer du vill skriva ut rapport för.',
  Employments: 'Anställningar',
  Organisations: 'Organisation',
  'Missing organisations': 'Saknar kravställningar',
  'Missing watched competences': 'Saknar våra kompetenser',
  'Required competences': 'Kravkompetenser',
  Reports: 'Rapporter',
  'Complete report': 'Fullständig rapport',
  Complete: 'Fullständig',
  'Required competences report': 'Kravrapport',
  'Should be handled': 'Bör hanteras',
  'Calender year': 'Kalenderår',
  'Calender year shows how many will expire within the next year':
    'Kalenderår visar hur många som förfaller inom nästa år',
  Homepage: 'Hemsida',
  Tree: 'Översikt',
  List: 'Lista',
  Found: 'Hittade',
  Requirement: 'Krav',
  Activities: 'Aktiviteter',
  'Shows activities in the company': 'Visar aktiviteter på företaget',
  'Organisation requirements': 'Organisationskrav',
  'Shows statuses of requirements on employees':
    'Visar hur många anställda som exempelvis uppfyller deras krav.',
  'Individual required competence statuses':
    'Visar status på alla kravställda kompetenser hos alla anställda.',
  'Connect users': 'Koppla anställda',
  'Shows the status of handled requirements.':
    'Visar status på hanterade kravkompetenser.',
  'Remove selected': 'Ta bort valda',
  'Remove selected company competences': 'Ta bort valda företagskompetenser',
  'Remove selected competences': 'Ta bort valda kompetenser',
  selected: 'valda',
  'Clear selection': 'Rensa valda',
  Employment: 'Anställning',
  'Welcome to Kompetensvy': 'Välkommen till Kompetensvy',
  'Fill in your information': 'Fyll i er information',
  'Fill in your information. Your company will be created.':
    'Fyll i er information. Ert företag kommer skapas.',
  'Connect your company': 'Koppla ert företag',
  'Test Kompetensvy': 'Testa kompetensvy',
  'via Fortnox to try Kompetensvy by yourself. You dont even have to contact us, simply type your email address in the field and follow the instructions. We will contact you at a later stage.':
    'via Fortnox för att testa Kompetensvy. Ni behöver inte ens kontakta oss, fyll helt enkelt i er e-postadress i fältet och följ instruktionerna. Vi kontaktar er senare.',
  'Thank you!': 'Tack!',
  'If you have any questions you can reach us at 0771-11 66 66 or mail to':
    'Om ni har några frågor kan ni nå oss på 0771-11 66 66 eller maila till',
  'Type your email address': 'Fyll i din e-postadress',
  Send: 'Skicka',
  'You will get an email with further instructions at the address':
    'Instruktionerna skickade till e-postadressen',
  'A problem has occurred, please contact us at info@kompetensvy.se':
    'Ett problem har uppstått, var vänlig kontakta oss på info@kompetensvy.se',
  'Go to login page.': 'Till logga in sidan.',
  'User created succesfully': 'Användare skapad',
  'First name is required.': 'Förnamn krävs.',
  'Last name is required.': 'Efternamn krävs.',
  'Password is required.': 'Lösenord krävs.',
  'This field must be checked': 'Detta fält måste vara ikryssat.',
  'I have read the': 'Jag har läst',
  'Terms and Conditions': 'Användarvillkoren',
  'Fill in your information and accept the terms and conditions.':
    'Fyll i din information och acceptera användarvillkoren.',
  'Passwords must match': 'Lösenorden måste matcha',
  Register: 'Skapa konto',
  Close: 'Stäng',
  Recall: 'Återkalla',
  'Risk assessment comment': 'Riskbedömingskommentar',
  'Expiration date': 'Förfallodatum',
  'Issue date': 'Utfärdat datum',
  'Signed by': 'Signerat av',
  'Signed/added by': 'Signerat/utskickat av',
  Workplace: 'Arbetsplats',
  'Work tasks': 'Arbetsuppgifter',
  'Fill in new password.': 'Fyll i ditt nya lösenord.',
  'Sign up': 'Skapa konto',
  'Competences marked with a star that you actively track in Kompetensvy.':
    'Kompetenser markerade med stjärna i bibliotek som ni aktivt bevakar i Kompetensvy.',
  'Search for tags to see what competences the employee fulfills against compared tag.':
    'Sök på taggar för att se vilka utbildningar den anställda uppfyller mot vald tagg.',
  'You have verified your social security number, you can now use your social security number to login.':
    'Du har verifierat ditt personnummer, du kan nu använda det med BankID för att logga in istället för e-post eller mobilnummer.',
  'Your social security number': 'Ditt personnummer',
  verified: 'verifierad',
  'Update tag': 'Uppdatera tagg',
  Tag: 'Tagg',
  'Number of connected competences': 'Antal kompetenser',
  'Could not register.': 'Kunde inte registrera konto.',
  'Back to login page.': 'Till logga in sidan.',
  'Register with email or phone number':
    'Registrera med e-postadress eller mobilnummer.',
  'Signing in...': 'Loggar in...',
  'Signing out...': 'Loggar ut...',
  "Couldn't retrieve all required user data. Try again or contact support": "Vi kunde inte hitta all nödvändig information om din användare. Försök igen eller kontakta supporten",
  "Couldn't sign you in, try again. If the issue isn't resolved, log out of all your Microsoft accounts before retrying":
    "Kunde inte logga in, försök igen. Om felet består, prova att logga ut från alla dina Microsoft konton innan du försöker igen",
  'Login failed, please try again or contact support': 'Kunde inte logga in, var vänlig försök igen eller kontakta supporten',
  "You need to allow access to the Kompetensvy application to be able to log in with Microsoft":
    "Du måste godkänna rättigheterna till Kompetensvy applikationen för att kunna logga in med Microsoft",
  "We couldn't find your user in our system. Fill in your information to create a user":
    'Vi kunde inte hitta en användare som matchade i vårt system. Fyll i information för att skapa din användare',
  'Connect user to company' : 'Koppla användare till företag',
  "We found your user in our system, but it wasn't connected to your company. To be able to login with Microsoft you need to connect your user to your company":
    "Vi hittade din användare i vårat system, men den var inte kopplad till ditt företag. För att kunna logga in med Microsoft så måste du koppla din användare till ditt företag",
  'Your user will be connected to "{{company}}". By accepting this you acknowledge that administrators of "{{company}}" can administer your competences, personal information etc.':
    'Din användare kommer att bli kopplad till "{{company}}". Genom att acceptera detta så kommer administratörer av "{{company}}" att kunna administrera dina kompetenser, personliga information m.m.',
  'You need to accept that your user will be connected to the company for Microsoft login to work' :
    'Du måste godkänna att din användare blir kopplad till företaget för att du ska kunna logga in med Microsoft',
  'Send a connection request to the employee.':
    'Skicka ut kopplingsförfrågan till medarbetaren. ',
  'Send connection request': 'Skicka kopplingsförfrågan',
  'Open PDF viewer with selected employees':
    'Öppna PDF-visare med valda anställda',
  'Delete selected employees': 'Ta bort valda anställda',
  'Remove connection': 'Radera koppling',
  'Reset password': 'Återställ lösenord',
  'Could not reset password.': 'Kunde inte återställa lösenord.',
  'Your licenses': 'Dina körtillstånd',
  'Amount of requirements you fulfill from': 'Antal krav du uppfyller från ',
  'Not valid requirements': 'Ej uppfyllda krav',
  'Need help': 'Behöver du hjälp',
  'Check our documentation': 'Se vår dokumentation',
  Documentation: 'Dokumentation',
  'You have requirements that are not valid but you have handled them. Go to employments to see which requirements.':
    'Ni har krav som inte är uppfyllda men är hanterade. Gå till anställningar för att se vilka det är.',
  'You have requirements that are expiring but you have handled them. Go to employments to see which requirements.':
    'Ni har krav som förfaller men är hanterade. Gå till anställningar för att se vilka det är.',
  Accepted: 'Accepterat',
  Starred: 'Stjärnmarkerad',
  'Log in': 'Logga in',
  'Sign in with Microsoft': 'Logga in med Microsoft',
  'Could not log in': 'Fel användarnamn eller lösenord',
  'Employees competences': 'Fullständig rapport',
  Accept: 'Acceptera',
  Denied: 'Nekat',
  Deny: 'Neka',
  Remove: 'Ta bort',
  Previous: 'Föregående',
  Next: 'Nästa',
  Verifications: 'Verifieringar',
  Tasks: 'Uppgifter',
  Task: 'Uppgift',
  'Add new': 'Lägg till ny',
  'Add new answer': 'Lägg till nytt svar',
  Answer: 'Svar',
  'Message is needed': 'Meddelande krävs',
  'Example message from': 'Exempel på meddelande från',
  'We recommend that you write who you are in the message.':
    'Vi rekommenderar att ni skriver vem som skickar meddelandet.',
  'Add tag': 'Skapa tagg',
  Tags: 'Taggar',
  'Choose tags': 'Välj taggar',
  Files: 'Filer',
  Employee: 'Anställd',
  'To employees': 'Gå till anställda',
  Appendix: 'Bilaga',
  'Date of birth': 'Personnummer',
  'Edit company competence': 'Redigera intern kompetens',
  'Missing permissions': 'Saknar tillstånd',
  'No permissions added yet. Keep track of your permissions digitally in kompetensvy.':
    'Inga tillstånd finns ännu. För att börja hålla koll på era tillstånd digitalt klicka på knappen nedanför.',
  'Competences that the user currently has.':
    'Aktuella kompetenser som den anställda har.',
  'Requirements based on the organisation. For example from a role.':
    'Kravställning baserat på Organisationen. Exempelvis krav från Arbetsroller.',
  'View PDF': 'Visa PDF',
  'Excel alternatives': 'Excel alternativ',
  'Add Handle': 'Hantera',
  'Download excel': 'Ladda hem Excel',
  'Remove employees': 'Ta bort anställda',
  'Selected competences will be shown in the report.':
    'Valda kompetenser kommer att visas i rapporten.',
  Excel: 'Excel',
  'Show competence image': 'Visa kompetensens bild',
  'All competences tracked by the company that the employee have.':
    'Alla kompetenser som ni är intresserade av som den anställde har. Dessa kompetenser är dom ni favoritmarkerat eller era interna.',
  'Company competences that the employee have.':
    'Interna kompetenser som den anställde har.',
  'Global competences tracked by the company that the employee have.':
    'Globala kompetenser som ni är intresserade av som den anställde har.',
  'Competences tracked by the company that the employee is missing.':
    'Kompetenser ni är intresserade av som den anställde saknar.',
  'Choose what extra information to show in the report.':
    'Välj vilken yttligare information som rapporten ska visa.',
  'Show employee social security number': 'Visa 8 siffrigt personnummer',
  'Show employee social security number last digits':
    'Visa fullständigt personnummer',
  'Show employee email address': 'Visa e-postadress',
  'Show employee mobile number': 'Visa mobilnummer',
  'Choose what competences from Kompetensvys library you like to keep track of.':
    'Välj vilka kompetenser från Kompetensvys bibliotek ni vill hålla koll på.',
  'From Kompetensvys library': 'Kompetensvy standard',
  'Add organisation part': 'Skapa organisationsdel',
  'Remove organisation': 'Ta bort organisation',
  'Rename organisation': 'Redigera namn',
  'Add requirements on chosen organisation.':
    'Lägg till eller redigera krav på vald organisation.',
  'Delete selected organisation and all children?':
    'Är du säker på att ni vill bort vald organisation och alla undernivåer?',
  'Add new certificate': 'Lägg till nytt bevis',
  'Modify global competence (optional)': 'Modifiera global kompetens (valfri)',
  'If you have a new certificate that renews this competence, simply add it by pressing the add new certificate button and filling in the information.':
    'Om du har nytt bevis som förnyar kompetensen kan du enkelt klicka på lägg till knappen och fylla i informationen i formuläret.',
  'Create competence': 'Spara kompetens',
  'Employee requirements': 'Anställningskrav',
  'Required competences missing': 'Kravkompetenser saknas',
  'The employee has no required competences yet.':
    'Den anställde har inga kravkompetenser ännu.',
  'Social security number must be valid': 'Personnummer måste vara giltigt',
  'Mobile number must be valid': 'Mobilnummret måste vara giltigt',
  'Email must be valid': 'E-postadressen måste vara giltigt',
  'Select competence to handle': 'Välj kompetens att hantera',
  'Description is needed': 'Beskrivning krävs',
  'Connection request': 'Kopplingsförfrågan',
  'has sent a license for': 'har skickat ett körtillstånd för',
  'Remove all': 'Ta bort alla filer',
  'date is a required field': 'Datum måste fyllas i',
  Validate: 'Attestera',
  'Needs validation' : 'Saknar attestering',
  'Missing verification' : 'Saknar verifiering',
  'Verification needs renewal every %x% months': 'Verifieringen behöver förnyas varje %x% månad',
  'Picture of both sides of the certificate is needed': 'Bild på båda sidor av certifikatet krävs',
  'Picture of the certificate is needed': 'Bild på certifikatet krävs',
  'Not validated': 'Ej attesterad',
  'Verified by': 'Attesterad av',
  'Remove competence': 'Ta bort kompetens',
  'Add company': 'Lägg till företag',
  Company: 'Företag',
  Features: 'Funktionalitet',
  'Add an email address or phone number. If the user already has an account the user will be added as admin. If the user does not have an account a email or sms will be sent with instruction for how to create an account. The created user will be an admin.':
    'Lägg till e-postadress eller mobilnummer. Om användaren redan finns kommer personen bli administratör. Om inte användaren finns kommer ett e-post/sms skickas ut och när användaren är skapad är den administratör.',
  'Create admin': 'Skapa administratör',
  'Pending admin creations': 'Väntade administratörer',
  Admins: 'Administratörer',
  Mutations: 'Aktivitet',
  'Company mutations': 'Företagets senaste aktiviteter',
  Users: 'Användare',
  'User mutations': 'Användares aktivitet',
  'Select machine types': 'Välj typ av tillstånd',
  'Permission name': 'Tillståndets namn',
  'Select machine and type': 'Välj maskin och typ',
  'Type is required': 'Typ krävs',
  'Yes/No': 'Ja eller nej',
  Text: 'Fritext',
  'Competence expires': 'Kompetensen förfaller',
  'This competence expires and need to be certified again.':
    'Kompetensen förfaller och behöver bli bevisad igen.',
  'Reoccurs in (months)': 'Förnyas inom (Månader)',
  'Competence expires at this date every year':
    'Kompetensen förfaller på detta datum varje år',
  'Verification': 'Verifiering',
  'The users must upload atleast one picture to validate the competence.': 'Användarna måste ladda upp bild på bevisets/certifikatets framsida för att verifiera kompetensen.',
  'The users must upload atleast two pictures to validate the competence.': 'Användarna måste ladda upp bilder på bevisets/certifikatets framsida och baksida för att verifiera kompetensen.',
  'Machine permission is required': 'Körtillstånd krävs',
  'Machine permission is required for:': 'Körtillstånd krävs för:',
  'The competence needs a valid machine permission': 'Kompetensen kräver ett giltigt körtillstånd',
  'Permission expires with the competence': 'Körtillståndet förfaller samtidigt som den anställdes kompetens',
  'An individual date will be set for each employee that gets assigned the permission': 'Ett individuellt förfallodatum måste anges på varje anställd som blir tilldelad tillståndet',
  'Use': 'Använd',
  'Allow': 'Tillåt',
  'Individual expiration date':
    'Individuellt förfallodatum',
  'Verification number': 'Verifierings nummer',
  'Add employee': 'Lägg till anställd',
  'Select employees': 'Välj anställda',
  'Send reminder': 'Skicka påminnelse',
  'Certificate added': 'Bevis skapat',
  'Task answer removed': 'Uppgift borttagen',
  'Connection email sent': 'Koppling skickad',
  'Connection removed': 'Koppling borttagen',
  'Internal competence added': 'Intern kompetens skapad',
  'Internal competence updated': 'Intern kompetens uppdaterad',
  'Image updated': 'Bild uppdaterad',
  'Verifications updated': 'Verifiering uppdaterad',
  'Files updated': 'Filer uppdaterad',
  'Tags updated': 'Taggar uppdaterad',
  'Competence removed': 'Kompetens borttagen',
  'Employee disconnected': 'Anställd frånkopplad',
  'Company added': 'Företag skapat',
  'Company updated': 'Företag uppdaterat',
  'Company profile updated': 'Företagsprofil uppdaterad',
  'Company removed': 'Företag borttaget',
  'Favorite toggled': 'Stjärnmarkering växlad',
  'Competence added': 'Kompetens skapad',
  'Competence updated': 'Kompetens uppdaterad',
  'Certificate verified toggle': 'Kompetens attestering ändrad',
  'Certificate removed': 'Bevis borttaget',
  'Employees connections sent': 'Anställda kopplingar skickade',
  'Employees connections resent': 'Anställda kopplingar påminnda',
  'Connection sent': 'Koppling skickad',
  'Employment added': 'Anställd skapad',
  'Employment organisations updated': 'Krav uppdaterad',
  'Employments organisations updated': 'Krav uppdaterad',
  'Employee updated': 'Anställd uppdaterad',
  'Employee removed': 'Anställd borttagen',
  'Employees removed': 'Anställda borttagna',
  'Handled removed': 'Hantering borttagen',
  'Saved connection': 'Sparat koppling',
  'ID06 certificates reloaded': 'ID06 bevis omladdade',
  'License folder added': 'Tillstånd mapp tillagd',
  'License folder renamed': 'Tillstånd mapp bytt namn',
  'License folder removed': 'Tillstånd mapp borttagen',
  'License added': 'Tillstånd skapat',
  'Employee added': 'Anställd tillagd',
  'License updated': 'Tillstånd uppdaterat',
  'Authorization added': 'Behörighet tilldelad',
  'Authorization updated': 'Behörighet uppdaterad',
  'Authorization removed': 'Behörighet borttagen',
  'Organisation added': 'Organisation skapad',
  'Organisation renamed': 'Organisation bytt namn',
  'Organisation removed': 'Organisation borttagen',
  'Competences added to organisation': 'Lagt till krav på organisation',
  'Competences removed from organisation': 'Krav på organisationen borttagen',
  'Personal requirements': 'Personliga krav',
  'Permission added': 'Tillstånd skapat',
  'Permission updated': 'Tillstånd uppdaterat',
  'Machine type updated': 'Maskintyp uppdaterat',
  'Permission revoked': 'Tillstånd återkallat',
  'User saved': 'Användare sparad',
  'Admin removed': 'Admin borttagen',
  'Reseller added': 'Återförsäljare skapad',
  'Reseller updated': 'Återförsäljare uppdaterad',
  'Reseller removed': 'Återförsäljare borttagen',
  'Tag added': 'Tagg skapad',
  'Tag removed': 'Tagg borttagen',
  'Email sent': 'E-postmeddelande skickat',
  'Email removed': 'E-post borttagen',
  'SMS sent': 'SMS skickat',
  'Add reseller': 'Skapa återförsäljare',
  'Mobile removed': 'Mobilnummer borttaget',
  'User notification removed': 'Notifikation borttagen',
  'Connection request sent to user': 'Koppling skickad till användaren',
  'Handled by employee not verified': 'Svar kräver attestering',
  'Executives will have this organisations preselected in filters.':
    'Chefer kommer ha valda organisationer förutvalda i filtrena.',
  'Update executive': 'Uppdatera chef',
  'Amount of handled by employee not attested that needs to be verified':
    'Antal svar som behöver attesteras',
  'Employees that are already connected.': 'Anställda som redan är kopplade.',
  'Only connected employees are shown. Permissions needs to be accepted by the employee to be valid therfor only connected employees can be selected.':
    'Endast kopplade anställda visas. Tillstånd kräver att personerna som blir tilldelade tillståndet accepterar.',
  'Only connected employees or employees that have denied the permission are shown. Permissions needs to be accepted by the employee to be valid therfor only connected employees can be selected. If the employee has denied the permission you can send it again.':
    'Endast kopplade anställda eller de som nekat tillståndet kan väljas. För att tillståndet ska vara giltigt måste personer acceptera tillståndet. Om en person nekat av misstag kan du skicka ut tillståndet igen.',
  'The competence will have to be re-validated every year on the specific month and day you choose.':
    'Kompetensen behöver bevisas igen varje år på den specifika månaden och dagen du väljer.',
  'The competence will have to be re-validated after the date set on each individual competence.':
    'Kompetensen behöver bevisas igen baserat på individuellt förfallodatum',
  'The competence will have to be re-validated after the amount of months you choose.':
    'Kompetensen behöver förnyas baserat på antalet månader du väljer.',
  'The user has to input a date.':
    'Användaren svarar genom att fylla i ett datum.',
  'The user answers by moving a switch.':
    'Användaren svarar genom att slå på eller av en switch',
  'The users answers by writing a text.':
    'Användaren svarar genom att skriva en text',
  'The users most do the task to validate the competence.':
    'Användarna måste genomföra denna uppgift för att godkänna kompetensen.',
  'Permission information': 'Tillståndets information',
  'Build your permission, we recommend that you detail your permissions as much as possible for legal compliance.':
    'Bygg era tillstånd. Kompetensvy rekommenderar att ni fyller i så detaljerat som möjligt för att säkerställa er mot eventuella lagkrav.',
  'What functionality should exist on the employees.':
    'Vad för funktionalitet ska finnas på dom anställda.',
  'What functionality should exist on the company.':
    'Vad för funktionalitet ska finnas på företaget.',
  'What functionality should the person have access to. If you choose none, the person will have read only access.':
    'Välj funktionalitet som personen ska ha. Om du inte väljer något har personen endast läsrättigheter.',
  'Add and remove employments under managed organisations':
    'Lägga till och ta bort anställda under valda organisationer',
  'Administrate employee information': 'Administrera anställdas information',
  'Attest and remove attests': 'Attestera och ta bort attestering',
  'Manage employees permissions': 'Redigera tillstånd',
  'Message sent to email': 'Skickat till mail',
  'Message could not be sent to email': 'Meddelande kunde inte skickas till',
  'Message sent to mobile number': 'SMS skickat till mobilnummer',
  'Message could not be sent to mobile number': 'SMS kunde inte skickas till',
  'Message sent to employee': 'Meddelande skickat till anställd',
  Access: 'Funktionalitet',
  'Calculate cost': 'Beräkna kostnad',
  'Company data': 'Företagsinformation',
  'Number of employments': 'Antal anställda',
  'Connected employments': 'Kopplade anställda',
  'BankID employments': 'Antal med BankID',
  'Authorization employments': 'Antal med behörighet',
  Administrators: 'Antal administratörer',
  'Use permissions.': 'Ska ha körtillstånd',
  Calculate: 'Beräkna',
  'Licenses competences': 'Körtillstånd kräver kompetens',
  'Send message to employees after handle':
    'Möjlighet att skicka SMS vid hantering',
  'Add api connection': 'Lägg till API-koppling',
  'If you choose to remove this competence this is a permanent action, it cannot be undone.':
    'Du kan inte ångra detta, kompetensen kommer att tas bort permanent.',
  'By pressing remove you acknowledge that this cannot be undone and you will remove the competence.':
    'Genom att trycka ta bort accepterar du att du inte kan ångra detta och kompetensen kommer att raderas.',
  'Create a organisation structure and requirements against for example units, departments, roles, projects...':
    'Skapa en företagsstruktur och kravställningar mot exempelvis enheter, avdelningar, arbetsroller, projekt m.m.',
  'Choose files that users can download when reading about this competence.':
    'Välj vilka filer användare kan ladda hem när de läser om denna kompetens.',
  'Expected date to be resolved': 'Förväntat datum att vara hanterat',
  'Add email address or phone number':
    'Lägg till e-postadress eller mobilnummer',
  'Turn off the status for this competence on this user.':
    'Stäng av statusen på denna kompetens för denna användare',
  'Reset your password with email or phone number':
    'Återställ ditt lösenord med e-postadress eller mobilnummer.',
  'If you choose a competence from the competences list it will fill out the form with information based on Kompetensvys library. You can change any information and when you save you will have created a internal competence that references the one from Kompetensvys library. This means that if a user fulfills the internal competence it also fulfills the one from Kompetensvys library.':
    'Om du väljer en kompetens från listan av kompetenser kommer formuläret att fyllas med information baserat på Kompetensvys bibliotek. Du kan ändra informationen, när du sparar kommer du att skapa upp en intern kompetens som refererar till den från Kompetensvys bibliotek. Om en person uppfyller er interna kompetens uppfylls även den från Kompetensvys bibliotek.',
  'Add an email address or phone number. If the user already has an account a connection request will be sent to the user. If the user does not have an account one will be created for them.':
    'Lägg till e-postadress eller mobilnummer. Om en användare redan finns registrerad på e-postadressen eller mobilnumret kommer en kopplingsförfrågan att skickas till användaren, användaren måste då logga in och acceptera. Om inte användaren finns redan kommer ett konto att skapas.',
  'A notification has been added to this account, the employee needs to log in and accept the request.':
    'En notifering har skickats till kontot, den anställde behöver logga in och acceptera förfrågan.',
  'When the employee has opened the email/text and created an account/accepted the connection this status will change.':
    'När den anställde har öppnat meddelandet och accepterat/skapat konto kommer statusen här att ändras.',
  'By pressing yes you acknowledge that this cannot be undone and you will remove the employee.':
    'Du kan inte ångra detta och den anställde kommer tas bort.',
  'By continuing you acknowledge that this cannot be undone and that you will remove the following employees from the company:':
    'Denna åtgärd kan inte ångras. Genom att fortsätta så bekräftar du att du vill ta bort följande anställda från företaget:',
  'Deselect employees by clicking on their x icon. Deselected employees will not be removed.':
    'Avmarkera anställda genom att klicka på deras x ikon. Avmarkerade anställda kommer ej att raderas från företaget.',
  'If you choose to remove this employee this is a permanent action, it cannot be undone. If this employee has connected an account the user will lose the employment but the account remains.':
    'Om ni raderar kopplingen till medarbetaren så försvinner den anställda från ert register i Kompetensvy. Den anställdas konto finns fortfarande kvar i Kompetensvy men ni kommer inte ha tillgång till den anställda. Om ni vill återuppta tillgång måste ni skicka en ny kopplingsförfrågan som den anställda måste acceptera.',
  'would like to merge their account with this one. If it is not you who sent this request you should deny this. If you accept you will be logged out and the accounts will merge, afterwards you will be able to login from the other account. Competences and employments will follow to the other account.':
    'vill sammanfoga sitt konto med ditt. Om det inte är du som skickat denna förfrågan ska du neka detta. Om du accepterar kommer du att loggas ut och kontona sammanfogas. Efteråt kommer du kunna logga in från det andra kontot. Kompetenser och anställningar följer med till det andra kontot.',
  'has sent a connection request to you. This means they will be able to administrate your competences, licenses, profile and more.':
    'har skickat en kopplingsförfrågan till dig. Detta innebär att dom kommer kunna administrera dina kompetenser, tilldela körtillstånd med mera.',
  'A reset token has been sent to the email adress or phone number.':
    'Ett återställningsmeddelande har skickats till e-postadressen eller mobilnumret.',
  'A message has been sent to the email adress or phone number.':
    'Ett meddelande har skickats till e-postadressen eller mobilnumret.',
  'A message has been sent to the email.':
    'Ett meddelande har skickats till e-postadressen',
  'Your employer has asked you to create an account. Fill in your information':
    'Ditt jobb har bett dig skapa ett konto. Fyll i information som efterfrågas.',
  'No watched competences yet. A great starting point is to first decide what competences you want to track in Kompetensvy. By choosing what competences you want to keep track of its easier and less messy to see every competence that is in the system. If you cant find the ones you want to keep track of, simply add it as your own internal competence and set your own demands on how it needs to be validated.':
    'En bra startpunkt för att komma igång är att välja vilka kompetenser ni är intresserade av i Kompetensvy. Genom att välja endast dom ni är intresserade av blir systemet enklare för er att leta i och bygga upp. Om ni inte hittar en kompetens ni vill använda så kan ni enkelt lägga till den själv. Ni bestämmer själv vad den ska heta, vilken beskrivning och hur era användare ska uppfylla den.',
  'No organisation tree added yet. To easier keep track of your employees we recommend that you build a organisation tree and place competences that is required to fulfill for a employee. When you have made your first organisation you can go into your employees and add that organisation to the user so the system shows flags if the competences in the organsation branch is valid, expiring or has expired.':
    'Ingen organisation är tillagd ännu. För att bygga kravbilder och enklare få en överblick rekommenderar vi att ni bygger upp er organisation och väljer vilka kompetenser ni vill spåra mot den organisationsdelen. När ni gjort det kan ni enkelt gå in på en anställd och lägga ut de korrekta organisationerna för att se om den anställda uppfyller sina krav, då ser ni enkelt en status som visar om kompetenserna är giltiga, förfaller eller har förfallit.',
  'Your company is missing employees. To start adding your employees click the button down below. After you have created your list of employees you can start adding their competences, building demands and much more.':
    'Företaget saknar anställda. För att lägga till de anställda man är intresserad att bevaka väljer ni antingen anställda i menyn eller klicka på knappen nedanför. samtidigt som ni skapar anställda kan ni välja vilka roller eller liknande den anställda ska ha.',
  'We suggest you upload images of your physical certificate, front and backside. Or anywhere that shows relevant information for someone who might control your certificate.':
    'Vi rekommenderar att du laddar upp bilder på ditt fysiska bevis. Eller alla dokument med relevant information om någon skulle kontrollera ditt bevis.',
  'A text has been sent to this number, open the text and follow the instructions.':
    'Ett sms har skickats till detta nummer, öppna smset och följ instruktionerna.',
  'A user with this number already exists. A notification has been added to this account, log in and accept the request.':
    'Ett konto med detta nummer existerar redan. En notifiering har skickats till detta konto, logga in och acceptera förfrågan.',
  'These are the phone numbers you have connected to your account.':
    'Detta är mobilnumrena du har kopplat till ditt konto',
  'Add your phone number as a way to login. You will get a text with instructions. You can use multiple phone numbers.':
    'Lägg till ett mobilnummer som sätt att logga in, du får ett sms med instruktioner.',
  'A email has been sent to this address, open the email and follow the instructions.':
    'Ett email har skickats till denna adress, öppna mailet och följ instruktionerna.',
  'A account with this email aldready exists. A notification has been added to this account, log in and accept the request.':
    'Ett konto med denna e-postadress existerar redan. En notifiering har skickats till detta konto, logga in och acceptera förfrågan.',
  'Add your email address as a way to login. You will get a email with instructions. You can use multiple emails.':
    'Lägg till e-postadress som inloggning. Du får ett email med instruktioner. Du kan ha flera e-postadresser samtidigt.',
  'These are the email adresses you have connected to your account.':
    'Detta är dom e-postadresser du har kopplat till ditt konto.',
  'Verify your social security number. This requires you to accept with BankID. When you have accepted you can use BankID to login instead of a password.':
    'Verifiera ditt personnummer. Detta kräver att du signerar med BankID. När du har signerat kan du använda BankID som inloggning istället för lösenord.',
  'Welcome. You have not added any competences yet. To be able to keep track of your competences press the button below to start adding them.':
    'Väkommen! Du har ännu inga sparade Kompetenser. För att börja spara dina kompetenser med giltighetstider och bilder klickar du på knappen lägg till kompetenser.',
  'By adding verifications you enable different ways to make sure a user has this competence. If you choose not to add any verification the competence will  only require a date to be valid and it will never expire.':
    'Genom att lägga till verifieringar aktiverar du olika sätt att se till att en användare har denna kompetens. Om du väljer att inte lägga till någon verifiering kommer kompetensen bara kräva ett startdatum, den kommer aldrig heller att förfalla.',
  Permissions: 'Tillstånd',
  'Work/driving permission': 'Arbets-/körtillstånd',
  'Work/driving permissions': 'Arbets-/körtillstånd',
  'Templates work/driving permissions': 'Mallar arbets-/körtillstånd',
  'Missing work/driving permissions': 'Saknar arbets-/körtillstånd',
  'Read more & answer': 'Läs mer & svara',
  'Accept or deny permission': 'Acceptera eller neka tillstånd',
  'Certified by global competence ': 'Pekar mot global kompetens - ',
  'Organisation have requirements': 'Krav finns',
  'Status for company permissions': 'Tillståndsstatus',
  Administration: 'Administration',
  'Machine types': 'Maskintyper',
  'Missing competences': 'Saknade kompetenser',
  'Show missing competences in report': 'Visa saknade kompetenser i rapport',
  Organisation: 'Organisation',
  Favorites: 'Favoriter',
  name: 'namn',
  description: 'beskrivning',
  All: 'Alla',
  Internal: 'Interna',
  Global: 'Globala',
  User: 'Användaren',
  BankID: 'BankID',
  'Your employments': 'Dina anställningar',
  'Select photo': 'Välj bild',
  cancel: 'avbryt',
  done: 'klar',
  Done: 'Klar',
  Active: 'Aktiva',
  Revoked: 'Återkallade',
  'Remove authorization': 'Ta bort behörighet',
  'Name is required': 'Namn krävs',
  Revoke: 'Återkalla',
  'Revoke selected': 'Återkalla valda',
  'Revoke permissions': 'Återkalla tillstånd',
  'By pressing revoke you acknowledge that this cannot be undone.':
    'Genom att trycka återkalla godkänner du att detta inte kan ångras.',
  'Your permissions': 'Dina tillstånd',
  'Not answerd': 'Ej svarat',
  Connect: 'Koppla',
  Declined: 'Tackat nej',
  'Open in new tab': 'Öppna i ny flik',
  'Go to certificate': 'Gå till bevis',
  'Download excel file of user competences':
    'Ladda ned excel fil med användarens kompetenser',
  Status: 'Status',
  'All certificates are validated': 'Alla bevis är attesterade',
  'Amount of certificates that needs to be validated':
    'Antal bevis som måste attesteras',
  'Remove employee connection': 'Ta bort kopplingen',
  'By pressing yes you acknowledge that you will remove the connection to the employee.':
    'Du kommer ta bort kopplingen till den anställda.',
  'Company competences or changed competences.':
    'Företagsanpassade eller ändrade kompetenser.',
  'Valid competences': 'Giltiga kompetenser',
  Handeld: 'Hanterad',
  'Not handeld': 'Ej hanterad',
  'Machine type': 'Maskintyp',
  'Company is required': 'Företag krävs',
  'File is required': 'Fil krävs',
  'Include': 'Inkludera',
  'Global competences': 'Globala kompetenser',
  'Accountable persons': 'Ansvariga personer',
  'has sent a permission for': 'har skickat ett tillstånd för',
  Overview: 'Översikt',
  Listview: 'Listvy',
  'Hide missing': 'Dölj saknade',
  'Hide global': 'Dölj globala',
  'Hide internal': 'Dölj interna',
  'ID06 Competences': 'ID06 Kompetenser',
  'ID06 companies': 'ID06 företag',
  "Select a company who's API the information will be used to fetch ID06 competences":
    'Välj ett företag där API informationen kommer hämta ID06 kompetenser',
  'All competences in company library.':
    'Alla kompetenser i företagsbiblioteket.',
  Others: 'Övriga',
  'Global competences that the employee has thats not in the company library.':
    'Globala kompetenser den anställda har som inte finns i företagsbiblioteket.',
  Create: 'Skapa',
  'This person is accountable that the work is done correctly and by competent personal.':
    'Dessa personer är ansvariga för att arbetet som tillståndet innefattar är korrekt och utförs av kompetent personal.',
  'Only connected emplyees are shown. Permissions needs to be accepted by the employee to be valid therfor only connected employees can be selected.':
    'Endast kopplade anställda visas i denna lista. Tillstånd måste accepteras av anställda för att vara godkända därför visas endast anställda med aktiva konton.',
  Issued: 'Utfärdat',
  'Only employees with a social security number will be fetched.':
    'Endast anställda med ett personnummer kommer hämtas.',
  'Fetch ID06 certificates': 'Hämta ID06 bevis',
  Fetch: 'Hämta',
  'Social security number fetched from employees user and is therefore locked.':
    'Personnummer är veriferat att det stämmer via BankID och därför låst.',
  'Create internal competence based on global competence':
    'Skapa intern kompetens baserad på global kompetens',
  'Read more': 'Läs mer',
  'Edit internal competence': 'Redigera intern kompetens',
  'Duplicate internal competence': 'Duplicera intern kompetens',
  'Company competence duplicated': 'Intern kompetens blev duplicerad',
  'Choose which employees will get the role of being a executive, this will give them the option to administrate people under the whole organisation. The users must have an account and be connected to your company.':
    'Välj vilka anställda som ska få rollen chef. Detta ger dom möjligheten att administrera personer under alla organisationstaggar. För att bli tilldelad behörighet måste personen ha ett konto och vara kopplad mot ert företag.',
  Executives: 'Chefer',
  'No explanation': 'Inget svar',
  Explanation: 'Svar',
  'This competence is required. If you cant add all required information to make the competence valid explain why.':
    'Denna kompetens krävs. Om du inte kan lägga till all nödvändig information för att göra kompetensen giltig förklara varför.',
  Explain: 'Svara',
  ' I cant find my physical proof that i have this competence.':
    ' Jag kan inte hitta mitt fysiska utbildningsbevis.',
  Invoices: 'Fakturering',
  Reminders: 'Påminnelser',
  'Send sms or email to employees.':
    'Skicka SMS eller E-post till den anställda.',
  'Send email': 'Skicka E-post',
  More: 'Mer',
  Sort: 'Sortera',
  'Send sms': 'Skicka SMS',
  'Send both sms and email': 'Skicka SMS och E-post',
  'Send message': 'Skicka meddelande',
  'Message to employee': 'Meddelande till den anställda',
  'Employees with no email and mobile number will not get a message.':
    'Anställda som saknar e-post eller mobilnummer kommer inte få ett meddelande.',
  'Competence status': 'Kompetensstatus',
  'Employee specific information': 'Individuell information',
  'If you want to add different information than you filled in before on a specific person, open the dropdown and fill in the information.':
    'För att lägga till annorlunda information på en specifik person öppnar ni dropdown menyn och fyller i formuläret på den specifika personen.',
  'Remember that Kompetensvy is the sender, please write who sent the message.':
    'Kom ihåg att Kompetensvy står som avsändare, skriv gärna vem som skickar meddelandet.',
  'Handled status': 'Hanteradstatus',
  'Employee profile updated': 'Anställds profil uppdaterad',
  'Task files': 'Filer som visas på uppgiften',
  'Certificate front file is required': 'Fil för bevisets framsida krävs',
  'Certificate front and back files are required':
    'Filer för bevisets fram och baksida krävs',
  'Remove authorizations': 'Ta bort behörigheter',
  'Remove tags': 'Ta bort taggar',
  'By pressing remove you acknowledge that this cannot be undone and you will remove the tags.':
    'Du kan inte ångra detta och taggarna kommer tas bort.',
  'By pressing remove you acknowledge that this cannot be undone and you will remove the authorizations.':
    'Du kan inte ångra detta och behörigheterna kommer tas bort.',
  'Assign executives': 'Tilldela chefer',
  'Tags removed': 'Taggar raderade',
  'Remove executives': 'Tabort chefer',
  'By pressing yes you acknowledge that this cannot be undone and you will remove the executives.':
    'Du kan inte ångra detta och cheferna kommer tas bort.',
  'Show employee image': 'Visa bild på den anställda',
  LatestActivity: 'Aktiv',
  'Must be a valid email': 'Måste vara en giltig e-postadress',
  'Email is required': 'E-postadress krävs',
  'A message has been sent to the email address.':
    'Ett meddelande har skickats till e-postadressen.',
  'Register with email.': 'Registrera med e-postadress.',
  'Reset your password with email': 'Återställ ditt lösenord med e-postadress',
  'A reset token has been sent to the email adress':
    'Ett återställningsmeddelande har skickats till e-postadressen',
  'Handled by employee not attested': 'Svar ej attesterat',
  'Handled by employee attested': 'Svar attesterade',
  'is required': 'krävs',
  'Handled requirements': 'Hanterade krav',
  Answers: 'Svar',
  'Change reseller': 'Byt återförsäljare',
  'Invalid date': 'Ogiltigt datum',
  'Amount of certificates that has been added': 'Antal bevis som är inlagda',
  'Employees that have an account in Kompetensvy':
    'Anställda som har ett konto i Kompetensvy',
  'The number of employees': 'Antal anställda',
  'Must have at least': 'Måste ha minst',
  'Password must have at least': 'Lösenordet måste ha minst',
  characters: 'tecken',
  'No required competences': 'Inga kravkompetenser',
  'No added competences': 'Inga kompetenser',
  here: 'här',
  'Employment with this information already exists':
    'En anställning med denna information finns redan',
  'This employee has been imported from {SOURCES}. Therefore this field cannot be manually changed':
    'Den anställde har blivit importerad från {SOURCES}. Därför kan inte detta fält ändras manuellt',
  'Reminders are used to keep competences up to date. If a required competence is missing, expiring or expired reminders will be sent to selected users.':
    'Om en kravställd kompetens saknas, förfaller eller är förfallen kommer påminnelser skickas ut till valda användare.',
  'Social security number exists on another user':
    'Personnumret finns redan på en annan användare',
  'Show employment number': 'Visa anställningsnummer',
  'Already created': 'Redan skapad',
  'A user with this email already created':
    'En användare med e-postadressen är redan skapad',
  'Added by': 'Tillagd av',
  'No options': 'Inga alternativ',
  'Add certificates': 'Lägg till kompetenser',
  'If you combine this with (Administrate employee information) certificates will automatically be validated':
    'Kombinerar ni denna med (Administrera anställdas information) kommer bevis automatiskt attesteras',
  Export: 'Rapport',
  'Export as': 'Rapport som',
  'Employees in the list below already have a connection request sent to them.':
    'Valda anställda som redan har en kopplingsförfrågan skickad till sig.',
  'Employees in the list below are missing email address.':
    'Valda anställda som saknar e-postadress.',
  'Marked employees have email and will get a message.':
    'Valda anställda som får kopplingsförfrågan.',
  'Update authorizations': 'Uppdatera behörigheter',
  Update: 'Uppdatera',
  'Organisations added within the select will be handled as or statements. Add more organisations that will be handled as and statements. Example, if in the first select the area Piteå and Skellefteå is selected and in the next the role programmer is selected. Employees in Piteå or Skellefteå and is a programmer is managed.':
    'Om du väljer två orter i första rutan exempelvis Piteå & Luleå då får personen se alla anställda i dessa två orter. Om du sedan lägger till fler organisationer och väljer arbetsrollen Snickare, då får personen endast se anställda med dom kombinerade organisationerna, det vill säga Piteå och Snickare eller Luleå och Snickare.',
  'Add organisations': 'Lägg till organisationer',
  'Cant reset password?': 'Kan du inte återställa lösenord?',
  'Common issues': 'Vanliga problem',
  'If you cannot reset your password you do not have an account registered to that email address.':
    'Om du inte kan återställa ditt lösenord finns det inte ett konto registrerat med den e-postadressen du angett.',
  'Check your email inbox for a mail from Info@kompetensvy.se, you might have to search in the junk mail.':
    'Kolla i din e-post inkorg för ett mail från Info@kompetensvy.se, du kanske måste söka i skärpkorgen.',
  'If this does not solve your problem, please contact your administrator or us at info@kompetensvy.se and we will look into it.':
    'Om inget av dessa alternativ löser ditt problem, kontakta din administratör eller oss på info@kompetensvy.se så hjälper vi dig.',
  'Add certificates to selected employees':
    'Lägg till kompetenser på valda anställda',
  'Export selected employees to PDF or Excel':
    'Exportera valda anställda till PDF eller Excel',
  'Authorization need to specify employment organisation.':
    'Måste välja en organisation för den anställda.',
  'Api connections': 'API-kopplingar',
  'Select API type': 'Välj typ av API',
  Run: 'Kör',
  Invoice: 'Fakturering',
  'No invoice cost': 'Ingen faktureringskostnad',
  'Per Admin': 'Per administratör',
  'Per Employment': 'Per anställd',
  'Per Connected employment': 'Per kopplad anställd',
  'Per Permission and connected Employment':
    'Per körtillstånd och kopplad anställd',
  'Per ID06': 'Per ID06 kompetens',
  'Per Azure': 'Per Azure kopplad anställd',
  'Per BankID': 'Per BankID kopplad person',
  'Per authorized': 'Per behörig',
  Total: 'Totalt',
  'Cost per count': 'Kostnad',
  'Company created': 'Företag skapat',
  'Company information': 'Företagsinformation',
  'When you press Test Kompetensvy data will be collected from Fortnox and your account will be created.':
    'När ni klickar på testa Kompetensvy hämtas data via Fortnox och ert konto kommer att skapas. Ni har då möjlighet att testa Kompetensvy.',
  Count: 'Antal',
  'If you have any questions feel free to reach us at 0771-11 66 66 or mail to':
    'Om ni har några frågor kan ni nå oss på 0771-11 66 66 eller maila till',
  'Login to start using Kompetensvy':
    'Logga in med dina uppgifter för att börja använda Kompetensvy',
  'Kompetensvy will collect the following data from Fortnox.':
    'Kompetensvy kommer att hämta följande data via Fortnox.',
  'Due date': 'Förfallotid',
  'Amount of months before the due date status begins.':
    'Antal månader i förväg systemet kommer visa statusen förfaller.',
  Months: 'Månader',
  'Choose how many months in advance the system shows yellow status. This does not change when a competence expires, it only decides how early Kompetensvy should show yellow status. This allows control on how far ahead you can handle a competence and how early to get mail for expiring competences.':
    'Välj själv hur många månader i förväg systemet ska visa gul status. Detta ändrar inte när kompetenser förfaller utan endast hur tidigt Kompetensvy ska visa gul status och på så sätt kan ni kontrollera hur långt fram i tiden ni vill kunna hantera kompetenser och även när ni ska få mail om att kompetenser förfaller.',
  'A employee has a competence that expires 2022-01-01, the system will show a yellow status from 2021-06-01 as standard.':
    'En anställd har en kompetens som förfaller 2022-01-01, systemet kommer då visa gul status från och med 2021-06-01 som standard.',
  News: 'Nyheter',
  'Discover the latest news from Kompetensvy.':
    'Upptäck senaste nyheterna från Kompetensvy.',
  Newer: 'Nyare',
  'Older news': 'Äldre nyheter',
  By: 'Av',
  'You have': 'Du har',
  'See all': 'Se alla',
  'Not valid competences': 'Ej godkända kompetenser',
  'not valid competences to handle': 'ej godkända kompetenser att hantera',
  'Expiring competences': 'Förfallande kompetenser',
  'expiring competences to handle': 'förfallande kompetenser att hantera',
  'Expired competences': 'Förfallna kompetenser',
  'expired competences to handle': 'förfallna kompetenser att hantera',
  'user connect request to handle': 'kopplingsförfrågan att hantera',
  'employment request to handle': 'anställningsförfrågan att hantera',
  'permission to handle': 'körtillstånd att hantera',
  'There are no notifications': 'Finns inga notifikationer',
  'Calculated expiring date': 'Automatiskt beräknat förfallodatum',
  'Information on the certificate': 'Information på beviset',
  'Fill in as much as possible': 'Fyll på så mycket som möjligt',
  'Certificate end date': 'Förfallodatum enligt bevis',
  'Education company': 'Utbildningsbolag',
  'Zoom out': 'Förminska',
  'Zoom in': 'Förstora',
  Subtitle: 'Underrubrik',
  'Use color in organisation overview.': 'Använd färg i översikt',
  'Organisation information': 'Organisation information',
  'Organisation tree information updated':
    'Organisation information uppdaterad',
  Instructor: 'Instruktör',
  'End date can be different from end date calculated by the system. We recommend that you fill in your certificates end date.':
    'Förfallodatum kan skilja sig åt från systemets automatiskt beräknade förfallodatum. Vi rekommenderar att ni fyller i förfallodatumet som står på ert bevis.',
  Comment: 'Kommentar',
  'Missing phone number and email': 'Saknar mobilnummer och e-postadress',
  'Only has email': 'Har endast e-postadress',
  'Only has phone number': 'Har endast mobilnummer',
  'Has both phone number and email': 'Har mobilnummer och e-postadress',
  'Create news': 'Skapa nyhet',
  'min read': 'min läsning',
  Cut: 'Klipp ut',
  Paste: 'Klistra in',
  Swedish: 'Svenska',
  And: 'Och',
  'Can administrate organisations': 'Administrera valda organisationer',
  'Can administrate library': 'Administrera bibliotek',
  'Allows the person to favourite competences and add internal competences':
    'Tillåter personen att stjärnmarkera kompetenser och skapa interna kompetenser.',
  'Allows the person to make changes to the organisation and requirements.':
    'Tillåter personen att ändra i valda organisationer och redigera kravkompetenser. ',
  'If you continue the people with expanded authorization will get a email.':
    'Om du fortsätter kommer personerna som får utökad behörighet att få ett mail.',
  'Last selected organisation': 'Senast vald organisation',
  'Use only task as verification': 'Använd endast uppgift som verifiering',
  'This means that users wont have to fill in the regular verification, only the task.':
    'Detta betyder att användare inte behöver skapa ett bevis utan endast svara på uppgiften.',
  English: 'Engelska',
  'Amount of competences marked as important to keep track of':
    'Antal kompetenser ni markerat som är viktiga för er att hålla koll på',
  Yes: 'Ja',
  'Employees with organisations': 'Anställda med organisationer',
  'Requirements on organisations': 'Krav på organisationer',
  'Show competences': 'Visa kompetenser',
  'Report type': 'Typ av rapport',
  'Report details': 'Rapport detaljer',
  'Employees library competences': 'Anställds kompetenser',
  'Employees required competences': 'Anställds kravkompetenser',
  'Report content': 'Rapport innehåll',
  'Choose what to show in the report': 'Välj vad som ska synas i rapporten',
  'Choose which competences to show in the report':
    'Välj vilka kompetenser ska visas i rapporten',
  'Choose type of report': 'Välj typ av rapport',
  'Depending on your selection a report will be generated':
    'Beroende på vilket val du gör kommer en rapport att genereras',
  'Report with employees in PDF form, you can include pictures':
    'Rapport med varje anställd i PDF format, man kan inkludera bilder',
  'Press the button below to create your report':
    'Tryck på knappen nedan för att skapa din rapport',
  Finished: 'Klart',
  'Report in Excel form, you can show every row as a competence or as a employee':
    'Rapport i Excel format, man kan välja att visa varje rad som en kompetens den anställda har eller att varje rad är en anställd',
  'Created date (asc)': 'Skapat datum (stigande)',
  'Created date (desc)': 'Skapat datum (fallande)',
  'Not accepted (asc)': 'Inte accepterad (stigande)',
  'Not accepted (desc)': 'Inte accepterad (fallande)',
  'Missing requirements': 'Saknar krav',
  Help: 'Hjälp',
  'Filter competences': 'Filtrera kompetenser',
  Change: 'Ändra',
  Theme: 'Tema',
  'Good Morning': 'Godmorgon',
  'Good Afternoon': 'Goddag',
  'Good Evening': 'Godkväll',
  'Create user': 'Skapa användare',
  'Fill in the form.': 'Fyll i formuläret',
  'Password set succesfully': 'Lösenordet ändrades',
  'All expiring competences this year': 'Alla kompetenser som förfaller i år',
  'All expiring competences next year':
    'Alla kompetenser som förfaller nästa år',
  'Here you can see things you need to handle':
    'Här visas saker som du behöver hantera',
  'Expiring requirements': 'Krav som förfaller',
  asc: 'stigande',
  desc: 'fallande',
  'Shows the status competences that needs to be validated.':
    'Status på kompetenser som kräver attestering',
  'Competences that need validation': 'Kompetenser som kräver attestering',
  'Your selections will be lost, continue?':
    'Dina markerade kommer att avmarkeras, fortsätt?',
  Selected: 'Markerade',
  Continue: 'Fortsätt',
  'Employment request': 'Anställningsförfrågan',
  'New employees imported': 'Nya anställda importerade',
  'New competence added': 'Ny kompetens skapad',
  'Updated company competence name': 'Kompetensnamn uppdaterat',
  'Update company competence verifications':
    'Uppdaterat verifieringar på kompetens',
  'Update company competence files': 'Kompetens filer uppdaterade',
  'Removed company competence': 'Kompetens borttagen',
  'Updated company competence image': 'Kompetens bild uppdaterad',
  'Favorite toggle': 'Favorite',
  'Employee connect email': 'Anställningskoppling skapad',
  'Delete employee connect email': 'Anställningskoppling borttagen',
  'Accept employee connect': 'Anställd kopplad',
  'Update employment organisations': 'Anställds krav uppdaterades',
  'Update employment image': 'Anställds bild uppdaterades',
  'Remove employment': 'Anställd togs bort',
  'Add handled': 'Hantering',
  'Add manager': 'Behörighet lades till',
  'Update manager': 'Behörighet uppdaterades',
  'Add organisation': 'Ny organisation skapades',
  'Add competences to organisation': 'Krav lades till organisation',
  'Remove competences from organisation': 'Krav togs bort från organisation',
  'Remove company competences from organisation':
    'Krav togs bort från organisation',
  'Update employments organisations': 'Anställds krav uppdaterade',
  'Add permission': 'Tillstånd skapades',
  'Employees resend connect': 'Anställningskoppling skickades igen',
  'Update company reminders': 'Företagets påminnelser uppdaterades',
  'Add handled by employee': 'Svar från anställd',
  'Remove handled by employee': 'Svar från anställd borttaget',
  'Add organisation tree information': 'Organisationsinformation skapades',
  ImportEmployments: 'Importerade anställda',
  RemoveCertificate: 'Ta bort bevis',
  AddCertificates: 'Lägg till bevis',
  AddCompanyCompetence: 'Lägg till kompetens',
  UpdateCompanyCompetence: 'Uppdatera kompetens',
  UpdateCompanyCompetenceVerifications: 'Uppdatera kompetens verifiering',
  UpdateCompanyCompetenceFiles: 'Uppdatera kompetens filer',
  RemoveCompanyCompetence: 'Ta bort kompetens',
  UpdateCompanyCompetenceImage: 'Uppdatera kompetensbild',
  ToggleFavorite: 'Favorit',
  EmployeeConnectEmail: 'Skickad koppling',
  DeleteEmployeeConnectEmail: 'Ta bort skickad koppling ',
  AcceptEmployeeConnect: 'Godkänd koppling',
  AddEmployment: 'Lägg till anställd',
  UpdateEmploymentOrganisations: 'Uppdaterad anställningsorganisation',
  UpdateEmploymentProfile: 'Uppdaterad anställningsprofil',
  UpdateEmploymentImage: 'Uppdaterad anställningsbild',
  RemoveEmployment: 'Ta bort anställd',
  AddHandled: 'Hanterad',
  RemoveHandled: 'Ta bort hanterad',
  AddManager: 'Ny behörighet',
  UpdateManager: 'Uppdatera behörighet',
  AddOrganisation: 'Ny organisation',
  RenameOrganisation: 'Organisations namn bytt',
  RemoveOrganisation: 'Organisation borttagen',
  AddCompetencesToOrganisation: 'Global kompetens tillagd i organisation',
  RemoveCompetencesFromOrganisation:
    'Global kompetens borttagen från organisation',
  AddCompanyCompetencesToOrganisation: 'Kompetens tillagd i organisation',
  RemoveCompanyCompetencesFromOrganisation:
    'Kompetens borttagen från organisation',
  RemoveManagers: 'Behörighet borttagen',
  UpdateEmploymentsOrganisations: 'Anställds organisation uppdaterad',
  AddPermission: 'Tillstånd skapad',
  EmployeesResendConnect: 'Anställningskoppling skickades igen',
  UpdateCompanyReminders: 'Företagets påminelser uppdaterades',
  AddHandledByEmployee: 'Kompetens hanterad av anställd',
  RemoveHandledByEmployee: 'Kompetens hanterad av anställd borttagen ',
  AddOrganisationTreeInformation: 'Organisation information skapad',
  SendMessage: 'Meddelande skickat',
  'Search by name, social security number or employment number':
    'Sök på namn, personnummer eller anställningsnummer',
  Filters: 'Filter',
  'SSG card number': 'SSG kortnummer',
  'ID06 card number': 'ID06 kortnummer',
  'Filter organisations': 'Filtrera organisationer',
  'Filter statuses': 'Filtrera statusar',
  'Or organisations': 'Eller organisationer',
  'And organisations': 'Och organisationer',
  'Or competences': 'Eller kompetenser',
  'And competences': 'Och kompetenser',
  and: 'och',
  or: 'eller',
  'You need to specify employment organisation.':
    'Du måste välja organisation.',
  'Add authorizations': 'Lägg till behörigheter',
  'Send reminders to authorized people about the state of their organisations':
    'Skicka påminnelser till behöriga personer om deras organisationer',
  'Company files': 'Företagsfiler',
  'Files added': 'Filer uppladdade',
  'Delete files': 'Radera filer',
  'Files deleted': 'Filera borttagna',
  Download: 'Ladda ner',
  'By pressing remove you acknowledge that this cannot be undone.':
    'Genom att trycka ta bort accepterar du att du inte kan ångra detta och filerna kommer att raderas.',
  'Are you sure you want to keep these favourites?':
    'Är du säker på att du vill behålla dessa favoriter?',
  'You have selected a competence from the global library and that competence is marked as favorite in your company. When the global competence is selected you will se both the favourite competece and the new company competence.':
    'Ni har valt en global kompetens som är stjärnmarkerad. Om du behåller stjärnmarkeringen kommer den globala och den interna kompetensen att synas samtidigt. Avmarkera stjärnmarkeringen om du inte vill se båda samtidigt.',
  'Show competences and employees': 'Visa kompetenser och anställda',
  'Only employees': 'Visa endast anställda',
  'Only competences': 'Visa endast kompetenser',
  'Your version of Kompetensvy is not correct and the application may not work as intended.':
    'Din version av Kompetensvy är inte senaste versionen. Detta kan göra att Kompetensvy inte fungerar korrekt.',
  'Try reloading the page, clear the cache or call support for help.':
    'Försök ladda om sidan, rensa cache eller ring support på 0771-11 66 66',
  'Your version': 'Din version',
  'Latest version': 'Senaste version',
  'Reload page': 'Ladda om sidan',
  'New update exists': 'Ny uppdatering finns',
  'Choose which competences to show on the employees in the report.':
    'Välj vilka kompetenser som syns i rapporten.',
  'If you select to show missing competences, all competencens will be compared against each chosen employees even if the person has the competence or not.':
    'Om du väljer att visa saknade kompetenser kommer alla kompetenser att jämföras på alla valda anställda oavsett om en person har kompetensen eller inte.',
  'Choose which organisations you would like to be shown in Excel. This will make each chosen organisation a column and will show if the employee is in that organisation or not.':
    'Välj organisation som ska visas i Excel. Detta kommer skapa en kolumn av varje vald organisation och visa om anställda tillhör den organisationen eller inte.',
  'Your account was successfully removed': 'Ditt konto har tagits bort',
  'Remove user email': 'Ta bort e-postadress',
  'User email removed': 'E-postadress borttagen',
  'No message sent, contact support':
    'Meddelande misslyckades, kontakta support',
  'Add all company competences the chosen employees have':
    'Lägg till alla företags kompetenser de valda anställda har',
  'Select all competences the employees have as required':
    'Välj alla kravställda kompetenser anställda har',
  Grading: 'Gradering',
  Motivation: 'Motivering',
  'Add new grading': 'Lägg till gradering',
  Gradable: 'Graderbar',
  'The competence needs to be graded.': 'Kompetens kräver gradering',
  'Gradable options': 'Graderingsalternativ',
  'Gradable options to show what level of skill the user has.':
    'Lägg till graderingar som ni sedan kan välja som nivåer av exempelvis skicklighet för en kompetens.',
  'Hide grading adding for employees':
    'Dölj möjligheten att anställde kan lägga till gradering själv',
  'Grading options cant be edited. You can hide this alternative by clicking the switch.':
    'Graderingar kan inte tas bort. Du kan dölja alternativet med switchen.',
  'Hide option': 'Dölj val',
  'Add gradable option': 'Lägg till graderingsalternativ',
  'Hide grading from employee': 'Dölj gradering för anställda',
  'Certificate is valid if this is valid':
    'Bevis är godkänt om detta är godkänt',
  'Use only this as verification': 'Använd endast detta som verifiering',
  'This means that users wont have to fill in the regular verification, only this.':
    'Detta betyder att användare inte behöver fylla i ett bevis utan endast detta.',
  Gradings: 'Graderingar',
  'This competence has gradings thats hidden from employees':
    'Denna kompetens har gradering som är dold',
  'Add grading': 'Lägg till gradering',
  'Filter grades': 'Filtrera graderingar',
  'Grading motivation': 'Motivering till gradering',
  'The motivation for the selected grade': 'Motiveringen till vald gradering.',
  'A grading must be set': 'Innehåller gradering',
  'Send messages to employees': 'Skicka meddelande till anställda',
  'If this is checked the message form will open after you click handle.':
    'Om du har valt detta kommer meddelande dialogen öppnas efter du klickar på hantera.',
  'Send reminders to people with authorizations about the state of their organisations':
    'Skicka påminnelse till behöriga personer om statusen på deras valda organisationer',
  Print: 'Skriv ut',
  'Requirements edited': 'Krav redigerade',
  'tasks must be done': 'Uppgifter',
  'gradings must be done': 'Graderingar',
  'A grading must be done': 'Innehåller gradering',
  'There are connected employees in the selected organisation or beneath.':
    'Det finns kopplade anställda i den valda organisationen eller nedanför.',
  'Remove grading': 'Ta bort gradering',
  'By pressing remove you acknowledge that this cannot be undone and you will remove the grading.':
    'Genom att trycka ta bort accepterar du att du inte kan ångra detta och graderingen kommer att raderas.',
  'Created by': 'Skapad av',
  'Following employees organisations cannot be removed, because if selected organisations is removed from the employees the employee will not be visible for you.':
    'Följande anställdas organisation kan inte raderas. Om vald organisation raderas från anställda så kommer anställda inte längre synas för dig.',
  'Hide organisations': 'Dölj organisation',
  'Hide employee': 'Dölj anställd',
  'Show or hide employee': 'Visa eller dölj anställd',
  'The employee is shown.': 'Den anställde visas i systemet.',
  'The employee is hidden.': 'Den anställda är dold i systemet.',
  'Employee visability status': 'Anställdas synlighetsstatus',
  'Direct add email to employee (superadmin)':
    'Lägg till kopplad e-postadress direkt (endast superadmin)',
  'Add email to employee': 'Lägg till e-postadress',
  Visable: 'Synliga',
  Hidden: 'Dolda',
  Show: 'Visa',
  Hide: 'Dölj',
  'Employee hidden status is updated':
    'Anställds synlighetstatus är uppdaterad',
  'Show or hide employee based on for example long time leave or parental leave. This will hide the employee from lists, statistics, email reminders and so on.':
    'Visa eller dölj anställd baserat på exempelvis långtidsfrånvaro eller föräldraledighet. Detta kommer att dölja den anställda från listor, statistik, e-post påminnelser med mera.',
  'Remove employee at specific date': 'Radera anställd på specifikt datum',
  'Date for removal': 'Datum för radering',
  'If you choose to remove the employee this is a permanent action, it cannot be undone. If this employee has connected an account the user will lose the employment but the account remains.':
    'Om ni raderar kopplingen till medarbetaren så försvinner den anställda från ert register i Kompetensvy. Den anställdas konto finns fortfarande kvar i Kompetensvy men ni kommer inte ha tillgång till den anställda. Om ni vill återuppta tillgång måste ni skicka en ny kopplingsförfrågan som den anställda måste acceptera.',
  'Create custom permission': 'Skapa anpassat tillstånd',
  'If this is checked you can create your own permission from free text.':
    'Om denna är ibockad kan ni skapa ett tillstånd baserat på fritext.',
  'has sent a custom permission': 'har skickat ett anpassat tillstånd',
  'Gradable competences': 'Graderbara kompetenser',
  'Remove company': 'Ta bort företag',
  'If you choose to remove this company this is a permanent action, it cannot be undone.':
    'Om ni väljer att radera detta företag är det ett permanent val, ni kan inte ångra det.',
  'By pressing remove you acknowledge that this cannot be undone and you will remove the company.':
    'Genom att trycka på radera accepterar ni att företaget kommer att raderas och att ni inte kan ångra er.',
  'Import certificates': 'Importera bevis',
  'You need to input atleast one email or phone number or social security number per person.':
    'Ni behöver minst e-postadress, telefonnummer eller personnummer för varje person.',
  'Technical issue, try again later': 'Tekniska problem, Försök igen senare',
  'BankID_outstandingTransaction': 'Starta BankID-appen.',
  'BankID_noClient': 'Starta BankID-appen.',
  'BankID_userSign': 'Skriv in din säkerhetskod i BankID-appen och välj Identifiera eller Skriv under.',
  'BankID_started': 'Söker efter BankID, det kan ta en liten stund … Om det har gått några sekunder och inget BankID har hittats har du sannolikt inget BankID som går att använda för den aktuella identifieringen/underskriften i den här datorn. Om du har ett BankID-kort, sätt in det i kortläsaren. Om du inte har något BankID kan du skaffa ett hos din bank. Om du har ett BankID på en annan enhet kan du starta din BankID-app där.',
  'BankID_expiredTransaction': 'BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Om du inte har något giltigt BankID kan du skaffa ett hos din bank. Försök sedan igen.',
  'BankID_Cancel': 'Åtgärden avbruten.',
  'BankID_userCancel': 'Åtgärden avbruten.',
  'BankID_cancelled': 'Åtgärden avbruten. Försök igen.',
  'BankID_startFailed': 'BankID-appen verkar inte finnas i din dator eller mobil. Installera den och skaffa ett BankID hos din bank. Installera appen från din appbutik eller https://install.bankid.com',
  'BankID_userExists': 'Detta personnummer används redan på ett annat konto.',
  'BankID_cantConnectUser': 'Något gick fel när du försökte koppla BankID till ditt konto. Försök igen.',
  'BankID_missingUser': 'Vi kunde inte hitta ett konto med ditt personnummer i Kompetensvy. Försök logga in med din e-postadress eller skapa ett konto om du inte redan har ett.',
  'BankID_certificateErr': 'Det BankID du försöker använda är för gammalt eller spärrat. Använd ett annat BankID eller skaffa ett nytt hos din bank.',
  'BankID_userMrtd': 'Fotografera och läs av din ID-handling med BankID-appen.',
  'Choose receipt receivers': 'Skicka kvittens till',
  'Receipt receivers': 'Kvittensmottagare',
  'Send receipt': 'Skicka kvittens',
  'A receipt receiver is required': 'Kvittensmottagare krävs',
  'Allow task file upload': 'Möjlighet att lägga in bevis, bild, dokument m.m.',
  'Task file upload is required': 'Bevis, bild, dokument m.m. är obligatoriskt',
  'Upload your certificate': 'Ladda upp ditt bevis, bild eller dokument',
  'Uploaded certificates': 'Uppladdade bevis, bilder eller dokument',
  'Uploaded certificate': 'Uppladdat bevis, bild eller dokument',
  'At least one file is required': 'Minst en fil krävs',
  'This field is required': 'Detta fält är obligatoriskt',
  'VERIFICATION_SPECIFIC_DATE_UNSPECIFIED': 'Både månad och dag måste vara ifyllda i en verifiering av typen "Kompetensen förfaller på detta datum varje år"'
};

export default translation;
